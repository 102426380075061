import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { DocumentEnquiryHeader } from "presentation/view/section/DocumentEnquiry/DocumentEnquiryHeader";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const DocumentEnquiryHeaderContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <MessageBarWrapper>
            {/* <DocumentEnquiryProvider> */}
            <GridStyles />
            <DocumentEnquiryHeader />
            {/* </DocumentEnquiryProvider> */}
        </MessageBarWrapper>
    </ANAInfoWrapper>;
};