import _ from "lodash";
import { CriteriaDirectoryTariffCoaCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useMemo } from "react";

import {
    HPHGroupTitle,
    InputField
} from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaEditTariffCoaPanel = () => {
    const DOC_ENQ_HDR_CONST = DocumentEnquiryConstant.Header;
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryTariffCoaCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria])

    const memoTariffCodeOptions = useMemo(() => {
        const groupOfTariffCodeOptions = searchCriteria.tariffTypeList?.map(tariffType => docEnquiryState.dynamicOptions.tariffCodeDropdownOptions[tariffType]);
        const commonTariffCodeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfTariffCodeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonTariffCodeOptions;
    }, [searchCriteria.tariffTypeList, docEnquiryState.dynamicOptions.tariffCodeDropdownOptions])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.TAR_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    docEnquiryVM.onMultipleDropdownChange(e, 'tariffTypeList')
                    docEnquiryVM.onDropdownChange(EMPTY_DOCUMENT_ENQUIRY_CRITERIA.tariffCodeList, 'tariffCodeList')
                }}
                options={docEnquiryState.dynamicOptions.tariffTypeDropdownOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.TAR_TYPE, searchCriteria.tariffTypeList, docEnquiryState.dynamicOptions.tariffTypeDropdownOptions, docEnquiryVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.TAR_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => docEnquiryVM.onMultipleDropdownChange(e, 'tariffCodeList')
                }
                options={memoTariffCodeOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.TAR_CODE, searchCriteria.tariffCodeList, memoTariffCodeOptions, docEnquiryVM])

    const memoCostCentreCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.COST_CENTRE_CODE}
                type="text"
                value={searchCriteria.costCentreCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'costCentreCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.COST_CENTRE_CODE, searchCriteria.costCentreCode, docEnquiryVM])

    const memoRevenueCompanyCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.REVENUE_COMPANY_CODE}
                type="text"
                value={searchCriteria.revenueCompanyCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'revenueCompanyCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.REVENUE_COMPANY_CODE, searchCriteria.revenueCompanyCode, docEnquiryVM])

    const memoProductServiceCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.PRODUCT_SERVICE_CODE}
                type="text"
                value={searchCriteria.productServiceCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'productServiceCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.PRODUCT_SERVICE_CODE, searchCriteria.productServiceCode, docEnquiryVM])

    const memoInterCompanyCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.INTER_COMPANY_CODE}
                type="text"
                value={searchCriteria.interCompanyCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'interCompanyCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.INTER_COMPANY_CODE, searchCriteria.interCompanyCode, docEnquiryVM])

    const memoSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.SALES_CHANNEL_CODE}
                type="text"
                value={searchCriteria.salesChannelCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'salesChannelCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.SALES_CHANNEL_CODE, searchCriteria.salesChannelCode, docEnquiryVM])

    const memoRevenueAcCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.REVENUE_AC_CODE}
                type="text"
                value={searchCriteria.revenueAcCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'revenueAcCode')} />
        </div>
        , [DOC_ENQ_HDR_CONST.REVENUE_AC_CODE, searchCriteria.revenueAcCode, docEnquiryVM])


    const memoPanel = useMemo(() => <div className='im-charge-data-search-criteria-group'>
        <HPHGroupTitle titleText={DOC_ENQ_HDR_CONST.TARIFF_COA} />
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.tariffTypeList ? memoTariffType : null}
            {docEnquiryState.enabledSearchCriteria.tariffCodeList ? memoTariffCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.costCentreCode ? memoCostCentreCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.revenueCompanyCode ? memoRevenueCompanyCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.productServiceCode ? memoProductServiceCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.interCompanyCode ? memoInterCompanyCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.salesChannelCode ? memoSalesChannelCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.revenueAcCode ? memoRevenueAcCode : null}
        </CriteriaItemContainer>
    </div>,
        [DOC_ENQ_HDR_CONST.TARIFF_COA, docEnquiryState.enabledSearchCriteria.costCentreCode, docEnquiryState.enabledSearchCriteria.interCompanyCode,
        docEnquiryState.enabledSearchCriteria.productServiceCode, docEnquiryState.enabledSearchCriteria.revenueAcCode, docEnquiryState.enabledSearchCriteria.revenueCompanyCode,
        docEnquiryState.enabledSearchCriteria.salesChannelCode, docEnquiryState.enabledSearchCriteria.tariffCodeList, docEnquiryState.enabledSearchCriteria.tariffTypeList,
            memoCostCentreCode, memoInterCompanyCode, memoProductServiceCode, memoRevenueAcCode, memoRevenueCompanyCode, memoSalesChannelCode, memoTariffCode, memoTariffType]);

    if (!enabledCriteria) return null;

    return memoPanel;
};
