import { SelectionChangedEvent } from "ag-grid-community";
import { customDispatchEvent, E_Custom_Dispatch_Event, E_Type_Of_Event } from "helpers/Events";
import { INITIAL_DOCUMENT_ENQUIRY_COL_DEF, transferRowData } from "presentation/constant/DocumentEnquiry/DocumentEnquiryColumnDefinition";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { useCallback, useRef } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";


export const DocumentEnquiryTablePanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const isShowRightCriteriaPanel = docEnquiryState.isShowRightCriteriaPanel;
    const docEnquiryVM = useDocumentEnquiryVM();
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    const tableDoubleClicked = (data: any) => {
        // if (data) {
        //     customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_REDIRECT_EVENT, 
        //         E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER, 
        //         { userName: anaInfoState.userName,
        //             data: data.data,
        //         }
        //     );
        // }

        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE, { isFullScreen: true, className: 'document-search-modal' });
        docEnquiryVM.openContainerDetail(data.data);
    }

    // useEffect(() => {
    //     if (onTableSelectionClicked) return;

    //     gridRef.current?.gridRef.current.api?.deselectAll();
    // }, [onTableSelectionClicked])

    // useEffect(() => {
    //     // const columnDefs = (INITIAL_DOCUMENT_ENQUIRY_COL_DEF.slice());

    //     // if (!docEnquiryState.selectedRows ||
    //     //     docEnquiryState.selectedRows.length <= 0) {        
    //     //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //     if (!onTableSelectionClicked) {
    //         gridRef.current?.gridRef.current.api?.deselectAll();
    //     }
    //     // }
    // })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        // setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        docEnquiryVM.updateSelectedRows(selectedRows);
    }, [docEnquiryVM])

    return <div className={`table-ag-gird im-charge-data-search-table-panel ag-theme-alpine`} style={{ position: 'relative' }}>
        <div className={`im-charge-data-search-table-panel-content ag-theme-alpine`}>
            {docEnquiryState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <NbisTable
                id='document-enquiry-table'
                columns={INITIAL_DOCUMENT_ENQUIRY_COL_DEF.slice()}
                // cacheBlockSize={30}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                showPaginator={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                gridHeight="customHeight"
                customHeight={"calc(100% - 10px)"}
                onRowDoubleClick={(e: any) => tableDoubleClicked(e)}
                selectionMode={false}
                rowSelection={"multiple"}
                suppressRowClickSelection={false}
                onSelectionChanged={handleSelectionChange}
                rowHeight={28}
                // rowBuffer={20}
                ref={gridRef}
                data={transferRowData(docEnquiryState.docHdrs ?? [])}
                groupDisplayType={"groupRows"}
                rowGroupPanelShow={"always"}
                autoMarginLeft={true}
                entrypoint={'documentEnquiry'}
                mustRefresh={true}

                columnSettingIconList={[
                    <>
                        <IconButton fileName={isShowRightCriteriaPanel ? "Icon-angle-left" : "Icon-angle-right"} size="medium" toolTipText={isShowRightCriteriaPanel ? "Close Right Panel" : "Open Right Panel"} toolTipArrow={false} onClick={docEnquiryVM.onHideRightCriteriaPanel} />
                    </>
                ]}
            />
        </div>
    </div>;
}