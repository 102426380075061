import { CriteriaDirectoryTariffCoaCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryCriteriaCheckboxRelatedField as checkboxRelatedFields } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteriaCheckboxRelatedField";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaPreviewTariffCoaPanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useCallback(() =>
        CriteriaDirectoryTariffCoaCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]),
        [docEnquiryState.enabledSearchCriteria])

    const isAllDefaultValue = useCallback(() => {
        const attributeField = CriteriaDirectoryTariffCoaCheckboxOption.filter(item => docEnquiryState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, field)
        })
        return isAllDefault
    }, [searchCriteria, docEnquiryState.enabledSearchCriteria]);
    const docEnqConstant = DocumentEnquiryConstant;

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        docEnquiryVM.showLoading();
        docEnquiryVM.onRemoveSearchCriteria(fieldName, isSearch);
        docEnquiryVM.hideLoading();
    }, [docEnquiryVM]);

    const memoPanel = useMemo(() => <>
        {(enabledCriteria() && !isAllDefaultValue()) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Tariff/Coa' />
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'tariffTypeList') && <Tags
                    label={`${docEnqConstant.Header.TAR_TYPE}  : ${searchCriteria.tariffTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tariffTypeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'tariffCodeList') && <Tags
                    label={`${docEnqConstant.Header.TAR_CODE}  : ${searchCriteria.tariffCodeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tariffCodeList')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'costCentreCode') && <Tags
                    label={`${docEnqConstant.Header.COST_CENTRE_CODE}  : ${searchCriteria.costCentreCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('costCentreCode')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'revenueCompanyCode') && <Tags
                    label={`${docEnqConstant.Header.REVENUE_COMPANY_CODE}  : ${searchCriteria.revenueCompanyCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('revenueCompanyCode')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'productServiceCode') && <Tags
                    label={`${docEnqConstant.Header.PRODUCT_SERVICE_CODE}  : ${searchCriteria.productServiceCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('productServiceCode')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'interCompanyCode') && <Tags
                    label={`${docEnqConstant.Header.INTER_COMPANY_CODE}  : ${searchCriteria.interCompanyCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('interCompanyCode')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'salesChannelCode') && <Tags
                    label={`${docEnqConstant.Header.SALES_CHANNEL_CODE}  : ${searchCriteria.salesChannelCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('salesChannelCode')}
                />}

                {isNonDefaultValue(searchCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA, checkboxRelatedFields, 'revenueAcCode') && <Tags
                    label={`${docEnqConstant.Header.REVENUE_AC_CODE}  : ${searchCriteria.revenueAcCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('revenueAcCode')}
                />}

            </div>
        </div> : null}</>
        , [docEnqConstant.Header.COST_CENTRE_CODE, docEnqConstant.Header.INTER_COMPANY_CODE, docEnqConstant.Header.PRODUCT_SERVICE_CODE, docEnqConstant.Header.REVENUE_AC_CODE,
        docEnqConstant.Header.REVENUE_COMPANY_CODE, docEnqConstant.Header.SALES_CHANNEL_CODE, docEnqConstant.Header.TAR_CODE, docEnqConstant.Header.TAR_TYPE, enabledCriteria,
            handleRemoveSearchCriteira, isAllDefaultValue, searchCriteria]);

    return memoPanel;
};
